import { Button } from "@nextui-org/react";
import Api from "../../config/Api";
import { saveAs } from "file-saver";
import { ArrowDownTrayIcon } from "@heroicons/react/24/outline";

const DownloadButton = ({ imageUrl, fileName }) => {
  const handleClick = async () => {
    try {
      const response = await Api.get(imageUrl, { responseType: "blob" });
      const blob = new Blob([response.data], { type: response.data.type });
      saveAs(blob, fileName);
    } catch (error) {
      console.error("Error downloading image:", error);
      // Handle download error (optional: display error message to user)
    }
  };

  return (
    <Button
      isIconOnly
      className='absolute z-100 bottom-5 left-[50%] translate-x-[-50%]'
      onClick={handleClick}
    >
      <ArrowDownTrayIcon className='w-[20px] stroke-[2px]' />
    </Button>
  );
};

export default DownloadButton;
