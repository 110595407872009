import * as Yup from "yup";

export const fields = [
  //   {
  //     name: "name",
  //     type: "text",
  //     label: "Nomi",
  //     placeholder: "Kategoriya nomini kiriting...",
  //     isRequired: true,
  //   },
  {
    name: "fullName",
    type: "text",
    label: "F.I.Sh",
    placeholder: "F.I.Sh ni kiriting...",
    isRequired: true,
  },

  {
    name: "storeNumber",
    type: "number",
    label: "Uy raqami",
    placeholder: "Uy raqamini kiriting...",
    isRequired: true,
  },
  {
    name: "size",
    type: "number",
    label: "Uy o'lchami - m²",
    placeholder: "Uy o'lchamini kiriting...",
    isRequired: true,
  },

  {
    name: "fullAmount",
    type: "number",
    label: "To'lov qiymati",
    placeholder: "To'lov qiymatini kiriting...",
    isRequired: true,
  },

  {
    name: "initialPayment",
    type: "number",
    label: "Boshlang'ich to'lov",
    placeholder: "Boshlang'ich to'lovni kiriting...",
    isRequired: true,
  },
  {
    name: "categoryId",
    type: "customSelect",
    label: "Kategoriyasi",
    placeholder: "Kategoriyasini tanlang...",
    isRequired: true,
  },
  {
    name: "contractNumber",
    type: "number",
    label: "Shartnoma raqami",
    placeholder: "Shartnoma raqamini kiriting...",
    isRequired: true,
  },
];

export const editFields = [
  ...fields,
  {
    name: "status",
    type: "customSelect",
    label: "Status",
    placeholder: "Statusni kiriting...",
    isRequired: true,
  },
];

export const emptyValues = {
  fullName: "",
  categoryId: "",
  storeNumber: "",
  initialPayment: "",
  fullAmount: "",
  contractNumber: "",
};

export const editEmptyFields = {
  ...emptyValues,
  status: "",
};

export const validationSchema = Yup.object().shape({
  fullName: Yup.string().required("Bo'sh bo'lmasligi kerak!"),
  categoryId: Yup.mixed().required("Bo'sh bo'lmasligi kerak!"),
  storeNumber: Yup.number().required("Bo'sh bo'lmasligi kerak!"),
  initialPayment: Yup.number().required("Bo'sh bo'lmasligi kerak!"),
  fullAmount: Yup.number().required("Bo'sh bo'lmasligi kerak!"),
  contractNumber: Yup.number().required("Bo'sh bo'lmasligi kerak!"),
});

export const editValidationSchema = Yup.object().shape({
  fullName: Yup.string().required("Bo'sh bo'lmasligi kerak!"),
  categoryId: Yup.mixed().required("Bo'sh bo'lmasligi kerak!"),
  storeNumber: Yup.number().required("Bo'sh bo'lmasligi kerak!"),
  initialPayment: Yup.number().required("Bo'sh bo'lmasligi kerak!"),
  fullAmount: Yup.number().required("Bo'sh bo'lmasligi kerak!"),
  contractNumber: Yup.number().required("Bo'sh bo'lmasligi kerak!"),
});

export const columns = [
  { name: "ID", uid: "id", sortable: true },
  { name: "F.I.Sh", uid: "fullName", sortable: true },
  { name: "Uy raqami", uid: "storeNumber", sortable: true },
  { name: "Shartnoma raqami", uid: "contractNumber", sortable: true },
  // { name: "To'lov qiymati", uid: "fullAmount", sortable: true },
  // {
  //   name: "Boshlang'ich to'lov qiymati",
  //   uid: "initialPayment",
  //   sortable: true,
  // },
  { name: "SANASI", uid: "createdAt" },
  // { name: "STATUS", uid: "status", sortable: true },
  { name: "ACTIONS", uid: "actions" },
];

export const INITIAL_VISIBLE_COLUMNS = [
  "id",
  "fullName",
  "storeNumber",
  "contractNumber",
  // "fullAmount",
  // "initialPayment",
  "createdAt",
  "actions",
];

export const searchIndexes = [
  "fullName",
  "storeNumber",
  "contractNumber",
  "fullAmount",
  "initialPayment",
];

export const statuses = [
  {
    id: "PROCESS",
    name: "Jarayonda",
  },
  {
    id: "PAID",
    name: "To'langan",
  },
  {
    id: "UNPAID",
    name: "To'lanmagan",
  },
];
