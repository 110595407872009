import * as Yup from "yup";

export const fields = [
  {
    name: "locationName",
    type: "text",
    label: "Lokatsiya nomi",
    placeholder: "Lokatsiya nomini kiriting...",
    isRequired: true,
  },
];

export const emptyValues = {
  locationName: "",
};

export const validationSchema = Yup.object().shape({
  locationName: Yup.string().required("Nomi bo'sh bo'lmasligi kerak!"),
});

export const columns = [
  { name: "ID", uid: "id", sortable: true },
  { name: "Lokatsiya nomi", uid: "locationName", sortable: true },
  { name: "SANASI", uid: "createdAt" },
  // { name: "STATUS", uid: "status", sortable: true },
  { name: "ACTIONS", uid: "actions" },
];

export const INITIAL_VISIBLE_COLUMNS = [
  "id",
  "locationName",
  "createdAt",
  "actions",
];

export const searchIndexes = ["locationName"];
