import React from "react";
import ProSidebar from "../../components/Sidebar/ProSidebar";
import { Route, Routes, Navigate } from "react-router";
import CategoriesPage from "../Categories/CategoriesPage";
import AgreementsPage from "../AgreementsPage/AgreementsPage";
import IncomesPage from "../IncomesPage/IncomesPage";
import OutgoingsPage from "../OutcomesPage/OutgoingsPage";
import EmployeesPage from "../EmployeesPage/EmployeesPage";
import RentStorePage from "../AgreementsPage/RentStorePage/RentStorePage";
import SaleStorePage from "../AgreementsPage/SaleStorePage/SaleStorePage";

import EmployeePage from "../EmployeesPage/EmployeePage";
import StatisticsPage from "../StatisticsPage";
import StoresPage from "../StoresPage/StoresPage";
import StorePage from "../StoresPage/StorePage/StorePage";
import InventoryPage from "../InventoryPage/InventoryPage";
import { ToastContainer } from "react-toastify";

const DashboardPage = () => {
  return (
    <div className='flex flex-col w-screen'>
      {/* <Navbar /> */}
      <div className='flex flex-row h-fit'>
        <ProSidebar />
        <div className='grow flex flex-col w-[500px] bg-neutral-100 overflow-y-scroll h-[calc(100vh_-_0px)]'>
          {" "}
          <Routes>
            {/* <Route path='/ads' element={<AdsPage />} />
            <Route path='/ads/:id' element={<AdsItemPage />} /> */}
            <Route path='/inventory' element={<InventoryPage />} />

            <Route path='/incomes' element={<IncomesPage />} />
            <Route path='/outgoings' element={<OutgoingsPage />} />
            <Route path='/statistics' element={<StatisticsPage />} />
            <Route path='/categories' element={<CategoriesPage />} />
            <Route path='/employees/:id' element={<EmployeePage />} />
            <Route path='/employees' element={<EmployeesPage />} />
            <Route path='/buildings' element={<StoresPage />} />
            <Route path='/buildings/:id' element={<StorePage />} />
            <Route path='/agreements' element={<AgreementsPage />} />
            <Route path='/agreements/rent/:id' element={<RentStorePage />} />
            <Route path='/agreements/sale/:id' element={<SaleStorePage />} />
            <Route path='/*' element={<Navigate to='/incomes' />} />
          </Routes>
        </div>
      </div>
      {/* <ToastContainer
        className='text-[14px]'
        position='top-right'
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme='colored'
      /> */}
    </div>
  );
};

export default DashboardPage;
