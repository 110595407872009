import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Api from "../../config/Api";
import { findIndex } from "lodash";
import { toast } from "react-toastify";

export const getAllCategories = createAsyncThunk(
  "categories/getAll",
  async (body = {}) => {
    const response = await Api.get("/category");
    return response.data;
  }
);

export const createCategory = createAsyncThunk(
  "categories/create",
  async (body) => {
    const response = await Api.post("/category", body);
    return response.data;
  }
);

export const updateCategory = createAsyncThunk(
  "categories/update",
  async (body) => {
    const response = await Api.put(`/category/${body?.id}`, body);
    return response.data;
  }
);

export const deleteCategory = createAsyncThunk(
  "categories/delete",
  async (body) => {
    const response = await Api.delete(`/category/${body?.id}`);
    return response.data;
  }
);

export const getAllCategoryItems = createAsyncThunk(
  "categories/getAllStore",
  async (body = {}) => {
    const response = await Api.get("/categoryItem");
    return response.data;
  }
);

export const createCategoryItem = createAsyncThunk(
  "categories/createStore",
  async (body) => {
    const response = await Api.post("/categoryItem", body);
    return response.data;
  }
);

export const updateCategoryItem = createAsyncThunk(
  "categories/updateStore",
  async (body) => {
    const response = await Api.put(`/categoryItem/${body?.id}`, body);
    return response.data;
  }
);

export const deleteCategoryItem = createAsyncThunk(
  "categories/deleteStore",
  async (body) => {
    const response = await Api.delete(`/categoryItem/${body?.id}`);
    return response.data;
  }
);

export const uploadImageCategory = createAsyncThunk(
  "categories/uploadImage",
  async (body, { rejectWithValue }) => {
    const formData = new FormData();
    formData.append("file", body?.file);
    try {
      const response = await Api.post("/fayl/upload", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      let reqBody = {
        fileEntityIds: [response?.data?.id].sort(),
        locationName: body?.locationName,
      };
      const res = await Api.put(`/category/${body?.id}`, reqBody);

      body?.removeSelection();
      return res.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const removeImage = createAsyncThunk(
  "categories/removeImage",
  async (body, { rejectWithValue }) => {
    try {
      const response = await Api.delete(
        `/category/${body?.categoryId}/deleteFile/${body?.fileEntityId}`
      );
      // body?.navigate();
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const categoriesSlice = createSlice({
  name: "categories",
  initialState: {
    categories: null,
    categoryItems: null,
    loading: false,
    error: null,
  },
  reducers: {
    resetError: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      ///------------ GET Categories ------------------/////
      .addCase(getAllCategories.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllCategories.fulfilled, (state, action) => {
        state.loading = false;
        state.categories = action.payload?.content;
      })
      .addCase(getAllCategories.rejected, (state, action) => {
        state.loading = false;
        toast.error("Xatolik");
        state.error = action.payload;
      })

      ///------------ CREATE Categories ------------------/////
      .addCase(createCategory.pending, (state) => {
        state.loading = true;
      })
      .addCase(createCategory.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.categories = [...state.categories, payload];
        toast.success("Kategoriya qo'shildi!");
      })
      .addCase(createCategory.rejected, (state, action) => {
        state.loading = false;

        toast.error("Xatolik");
        state.error = action.payload;
      })

      ///------------ delete Categories ------------------/////
      .addCase(deleteCategory.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteCategory.fulfilled, (state, { payload }) => {
        state.loading = false;
        const ctgIndex = findIndex(state.categories, { id: payload?.id });
        state.categories.splice(ctgIndex, 1);
        toast.success("Kategoriya o'chirildi!");
      })
      .addCase(deleteCategory.rejected, (state, action) => {
        state.loading = false;
        toast.error("Xatolik");
        state.error = action.payload;
      })

      ///------------ UPDATE Categories ------------------/////
      .addCase(updateCategory.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateCategory.fulfilled, (state, { payload }) => {
        state.loading = false;
        const ctgIndex = findIndex(state.categories, { id: payload?.id });
        payload.itemCount = state.categories[ctgIndex]?.itemCount;
        state.categories[ctgIndex] = payload;
        toast.success("Kategoriya o'zgartirildi!");
      })
      .addCase(updateCategory.rejected, (state, action) => {
        state.loading = false;
        toast.error("Xatolik");
        state.error = action.payload;
      })

      ///------------ UPDATE Categories ------------------/////
      .addCase(uploadImageCategory.pending, (state) => {
        state.loading = true;
      })
      .addCase(uploadImageCategory.fulfilled, (state, { payload }) => {
        state.loading = false;
        const ctgIndex = findIndex(state.categories, { id: payload?.id });
        state.categories[ctgIndex] = payload;
        toast.success("Rasm yuklandi!");
      })
      .addCase(uploadImageCategory.rejected, (state, action) => {
        state.loading = false;
        toast.error("Xatolik");
        state.error = action.payload;
      })

      ///------------ delete payment ------------------/////
      .addCase(removeImage.pending, (state) => {
        state.loading = true;
      })
      .addCase(removeImage.fulfilled, (state, { payload }) => {
        state.loading = false;
        const ctgIndex = findIndex(state.categories, { id: payload?.id });
        state.categories[ctgIndex] = payload;
        toast.success("Rasm o'chirildi!");
      })
      .addCase(removeImage.rejected, (state, action) => {
        state.loading = false;
        toast.error("Xatolik");
        state.error = action.payload;
      })

      ///------------ GET Categories ------------------/////
      .addCase(getAllCategoryItems.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllCategoryItems.fulfilled, (state, action) => {
        state.loading = false;
        state.categoryItems = action.payload?.content;
      })
      .addCase(getAllCategoryItems.rejected, (state, action) => {
        state.loading = false;
        toast.error("Xatolik");
        state.error = action.payload;
      })

      ///------------ CREATE Categories ------------------/////
      .addCase(createCategoryItem.pending, (state) => {
        state.loading = true;
      })
      .addCase(createCategoryItem.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.categoryItems = [...state.categoryItems, payload];
        toast.success("Kategoriya qo'shildi!");
      })
      .addCase(createCategoryItem.rejected, (state, action) => {
        state.loading = false;
        toast.error("Xatolik");
        state.error = action.payload;
      })

      ///------------ delete Categories ------------------/////
      .addCase(deleteCategoryItem.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteCategoryItem.fulfilled, (state, { payload }) => {
        state.loading = false;
        const ctgIndex = findIndex(state.categoryItems, { id: payload?.id });
        state.categoryItems.splice(ctgIndex, 1);
        toast.success("Kategoriya o'chirildi!");
      })
      .addCase(deleteCategoryItem.rejected, (state, action) => {
        state.loading = false;
        toast.error("Xatolik");
        state.error = action.payload;
      })

      ///------------ UPDATE Categories ------------------/////
      .addCase(updateCategoryItem.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateCategoryItem.fulfilled, (state, { payload }) => {
        state.loading = false;
        const ctgIndex = findIndex(state.categoryItems, { id: payload?.id });
        payload.itemCount = state.categoryItems[ctgIndex]?.itemCount;
        state.categoryItems[ctgIndex] = payload;
        toast.success("Kategoriya o'zgartirildi!");
      })
      .addCase(updateCategoryItem.rejected, (state, action) => {
        state.loading = false;
        toast.error("Xatolik");
        state.error = action.payload;
      });
  },
});

export const { resetError } = categoriesSlice.actions;

export default categoriesSlice.reducer;
