import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  createPayment,
  deletePayment,
  deleteStore,
  getStoreById,
  getStorePaymentsById,
  updatePayment,
  updateStore,
} from "../storesSlice";
import { Avatar, Divider, AvatarGroup, Button } from "@nextui-org/react";
import { CurrencyDollarIcon, BanknotesIcon } from "@heroicons/react/24/solid";
import { getMoneyPattern } from "../../../utils/regex";
import ProTable from "../../../components/ProTable";
import {
  INITIAL_VISIBLE_COLUMNS,
  columns,
  fields,
  validationSchema,
  emptyValues,
  searchIndexes,
} from "./data";

import {
  editFields as storeFields,
  editValidationSchema as storeValidation,
  editEmptyFields as storeEmptyValues,
  statuses,
} from "../data";
import { getStoreImage } from "../../../utils/utils";
import StoreEditButton from "../../../components/Store/StoreEditButton";
import DeleteModal from "../../../components/Modals/DeleteModal";
import EditModal from "../../../components/Modals/EditModal";
import { getAllCategories } from "../../Categories/categoriesSlice";
import EmptyModal from "../../../components/Modals/EmptyModal";
import ReactImageGallery from "react-image-gallery";
import { original } from "@reduxjs/toolkit";
import DownloadButton from "../../../components/Store/buttons";
import {
  getAllSaleStores,
  getAllSaleStoresByStoreId,
} from "../../AgreementsPage/saleStoresSlice";
import {
  INITIAL_VISIBLE_COLUMNS_RENT,
  columnsRent,
  searchIndexesRent,
} from "./agreements";
import { getAllRentStoresByStoreId } from "../../AgreementsPage/rentStoreSlice";

const StorePage = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { store, storePayments } = useSelector((state) => state.stores);

  const { categories } = useSelector((state) => state.categories);

  const { saleStores } = useSelector((state) => state.saleStores);
  const { rentStores } = useSelector((state) => state.rentStores);

  useEffect(() => {
    dispatch(getAllCategories());
  }, []);

  useEffect(() => {
    dispatch(getStoreById(id));
    // dispatch(getStorePaymentsById(id));

    dispatch(getAllRentStoresByStoreId(id));
    dispatch(getAllSaleStoresByStoreId(id));
  }, []);

  return (
    <div className='flex flex-col w-full p-5 gap-5'>
      <div className='grid grid-cols-2 gap-8 w-full'>
        <div className='flex flex-col col-span-1 w-full p-5 bg-white rounded-xl h-fit'>
          <div className='flex flex-row justify-between'>
            <span className='text-[16px] text-black font-semibold'>
              {"Uy ma'lumotlari"}
            </span>
            <div>
              <StoreEditButton
                store={store}
                deleteModal={(str) => (
                  <DeleteModal
                    button={<Button color='danger'>{"O'chirish"}</Button>}
                    contextText={str?.storeNumber + " uy"}
                    handleSubmit={() => dispatch(deleteStore({ id: str?.id }))}
                  />
                )}
                editModal={(str) => (
                  <EditModal
                    button={
                      <Button
                        color='warning'
                        variant='shadow'
                        className='w-fit text-white'
                      >
                        {"Tahrirlash"}
                      </Button>
                    }
                    // ctgs={categories}
                    handlerSubmit={(reqBody) =>
                      dispatch(
                        updateStore({
                          ...reqBody,
                          fileEntityId: str?.fileEntity?.id,
                        })
                      )
                    }
                    fields={storeFields.map((el) => {
                      if (el.name === "categoryId") {
                        el.selectData = categories;
                        return el;
                      }

                      if (el.name === "status") {
                        el.selectData = statuses;
                        return el;
                      }

                      return el;
                    })}
                    validationSchema={storeValidation}
                    initialValues={{
                      id: str?.id,
                      contractNumber: str?.contractNumber,
                      storeNumber: str?.storeNumber,
                      size: str?.size,
                      status: str?.status,
                      fullName: str?.fullName,
                      fullAmount: str?.fullAmount,
                      initialPayment: str?.initialPayment,

                      categoryId: str?.category?.id + "",
                    }}
                  />
                )}
              />
            </div>
          </div>
          <div className='flex flex-row gap-5 mt-3'>
            <img
              className='w-[180px] aspect-square object-cover rounded-2xl'
              src={getStoreImage(store?.fileEntity)}
            />
            <div className='flex flex-col grow'>
              <div className='flex flex-row justify-between mt-5'>
                <span className='text-[14px] text-neutral-600 font-semibold'>
                  {"F.I.Sh"}
                </span>
                <span className='text-[14px] text-black font-semibold'>
                  {store?.fullName}
                </span>
              </div>
              <Divider className='my-2' />
              <div className='flex flex-row justify-between'>
                <span className='text-[14px] text-neutral-600 font-semibold'>
                  {"Uy raqami"}
                </span>
                <span className='text-[14px] text-black font-semibold'>
                  {store?.storeNumber}
                </span>
              </div>
              <Divider className='my-2' />
              <div className='flex flex-row justify-between'>
                <span className='text-[14px] text-neutral-600 font-semibold'>
                  {"Shartnoma raqami"}
                </span>
                <span className='text-[14px] text-black font-semibold'>
                  {store?.contractNumber}
                </span>
              </div>
              <Divider className='my-2' />
              <div className='flex flex-row justify-between'>
                <span className='text-[14px] text-neutral-600 font-semibold'>
                  {"Uy o'lchami"}
                </span>
                <span className='text-[14px] text-black font-semibold'>
                  {`${store?.size} m²`}
                </span>
              </div>
            </div>
          </div>
        </div>

        <EmptyModal
          size='2xl'
          title={"Kategoriya"}
          contextText={store?.category?.locationName}
          context={
            <div className='flex flex-wrap w-full flex-row gap-2'>
              <ReactImageGallery
                // renderItem={(el) => <div>{el}</div>}
                // renderCustomControls={}
                showThumbnails={true}
                thumbnailPosition='bottom'
                items={store?.category?.fileEntity.map((img) => ({
                  renderThumbInner: () => (
                    <img
                      className='w-[100%] aspect-square object-cover'
                      src={getStoreImage(img)}
                    />
                  ),
                  renderItem: () => (
                    <div className='flex w-full justify-center items-center'>
                      <DownloadButton
                        imageUrl={getStoreImage(img)}
                        fileName={img?.name}
                      ></DownloadButton>
                      <img
                        className='w-[55%] aspect-square object-cover'
                        src={getStoreImage(img)}
                      />
                    </div>
                  ),
                  original: getStoreImage(img),

                  thumbnail: getStoreImage(img),
                  showThumbnails: true,
                }))}
              />
              {/* {store?.category?.fileEntity.map((img) => (
                <img
                  src={getStoreImage(img)}
                  className='w-[130px] aspect-square rounded-xl object-cover'
                />
              ))} */}
            </div>
          }
          button={
            <button className='flex flex-col col-span-1 p-5 gap-1 w-full h-fit bg-white rounded-2xl'>
              <span className='text-[14px] text-neutral-900 font-semibold'>
                {"Kategoriya"}
              </span>
              <div className='flex flex-row w-full justify-between'>
                <span className='text-[12px] text-neutral-600 font-semibold'>
                  {"Lokatsiya nomi"}
                </span>
                <span className='text-[12px] text-black font-semibold'>
                  {store?.category?.locationName}
                </span>
              </div>
              <AvatarGroup
                isGrid
                isBordered
                max={7}
                className='pl-2 pr-8 py-3 w-full'
              >
                {store?.category?.fileEntity.map((img) => (
                  <Avatar
                    isBordered
                    color='primary'
                    size='md'
                    src={getStoreImage(img)}
                  />
                ))}
              </AvatarGroup>
              {/* <Divider className='my-2' /> */}
            </button>
          }
        />

        {/* <div className='grid grid-cols-2 col-span-3 w-full gap-3'>
          <div className='flex flex-col gap-1 w-full bg-white rounded-2xl p-5 h-fit'>
            <CurrencyDollarIcon className='w-[28px] text-primary mb-1' />
            <span className='text-[14px] text-neutral-500 font-semibold'>
              {"To'lov qiymati"}
            </span>
            <span className='text-[16px] text-black font-bold mb-1'>
              {`${getMoneyPattern(store?.fullAmount, ",")} UZS`}
            </span>
          </div>

          <div className='flex flex-col gap-1 w-full bg-white rounded-2xl p-5 h-fit'>
            <BanknotesIcon className='w-[28px] text-orange-500 mb-1' />
            <span className='text-[14px] text-neutral-500 font-semibold'>
              {"Boshlang'ich to'lov"}
            </span>
            <span className='text-[16px] text-black font-bold mb-1'>
              {`${getMoneyPattern(store?.initialPayment, ",")} UZS`}
            </span>
          </div>
          <div className='flex flex-col gap-1 w-full bg-white rounded-2xl p-5 h-fit'>
            <BanknotesIcon className='w-[28px] text-green-500 mb-1' />
            <span className='text-[14px] text-neutral-500 font-semibold'>
              {"To'langan summa"}
            </span>
            <span className='text-[16px] text-black font-bold mb-1'>
              {`${getMoneyPattern(
                storePayments?.reduce((accumulator, currentValue) => {
                  return accumulator + currentValue?.newPayment;
                }, 0),
                ","
              )} UZS`}
            </span>
          </div>
          <div className='flex flex-col gap-1 w-full bg-white rounded-2xl p-5 h-fit'>
            <BanknotesIcon className='w-[28px] text-pink-500 mb-1' />
            <span className='text-[14px] text-neutral-500 font-semibold'>
              {"Qoldiq summa"}
            </span>
            <span className='text-[16px] text-black font-bold mb-1'>
              {`${getMoneyPattern(
                store?.fullAmount -
                  store?.initialPayment -
                  storePayments?.reduce((accumulator, currentValue) => {
                    return accumulator + currentValue?.newPayment;
                  }, 0),
                ","
              )} UZS`}
            </span>
          </div>
        </div> */}
      </div>

      <div className='flex flex-col w-full bg-white p-5 rounded-2xl'>
        {saleStores && rentStores && (
          <ProTable
            isCreatable={false}
            tableName='Kelishuvlar'
            searchIndexes={searchIndexesRent}
            navButton={(type) =>
              type === "rent" ? "/agreements/rent" : "/agreements/sale"
            }
            // viewButtonUrl='/agreements/rent'
            // createSubmitHandler={(reqBody) =>
            //   dispatch(createRentStore(reqBody))
            // }
            // editSubmitHandler={(reqBody) => dispatch(updateRentStore(reqBody))}
            // deleteSubmitHandler={(id) => dispatch(deleteRentStore({ id }))}
            columns={columnsRent}
            initialVisibleColumns={INITIAL_VISIBLE_COLUMNS_RENT}
            tableData={[
              ...rentStores.map((el) => ({ ...el, agreementType: "rent" })),
              ...saleStores.map((el) => ({ ...el, agreementType: "sale" })),
            ]}
          />
        )}
      </div>

      {/* <div className='flex flex-col w-full bg-white p-5 rounded-2xl'>
        {storePayments && (
          <ProTable
            searchIndexes={searchIndexes}
            isSearch={false}
            tableName="To'lov"
            //   viewButtonUrl='/stores'
            createSubmitHandler={(reqBody) =>
              dispatch(createPayment({ data: reqBody, id }))
            }
            editSubmitHandler={(reqBody) =>
              dispatch(
                updatePayment({
                  storeId: id,
                  paymentId: reqBody?.id,
                  data: reqBody,
                })
              )
            }
            deleteSubmitHandler={(id) =>
              dispatch(deletePayment({ paymentId: id }))
            }
            columns={columns}
            initialVisibleColumns={INITIAL_VISIBLE_COLUMNS}
            tableData={storePayments}
            createData={{
              fields,
              initialValues: emptyValues,
              validationSchema,
            }}
            editData={{ fields, initialValues: (str) => str, validationSchema }}
          />
        )}
      </div> */}
    </div>
  );
};

export default StorePage;
