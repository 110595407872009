export const columnsRent = [
  { name: "ID", uid: "id", sortable: true },
  { name: "F.I.Sh", uid: "store.fullName", sortable: true },
  { name: "Kelishuv turi", uid: "agreementType", sortable: true },
  { name: "Uy raqami", uid: "store.storeNumber", sortable: true },
  { name: "Shartnoma raqami", uid: "store.contractNumber", sortable: true },
  // { name: "To'lov qiymati", uid: "fullAmount", sortable: true },
  // {
  //   name: "Boshlang'ich to'lov qiymati",
  //   uid: "initialPayment",
  //   sortable: true,
  // },
  { name: "SANASI", uid: "createdAt" },
  // { name: "STATUS", uid: "status", sortable: true },
  { name: "ACTIONS", uid: "actions" },
];

export const INITIAL_VISIBLE_COLUMNS_RENT = [
  "id",
  "store.fullName",
  "agreementType",
  "store.storeNumber",
  "store.contractNumber",
  // "fullAmount",
  // "initialPayment",
  "createdAt",
  "actions",
];

export const searchIndexesRent = [
  "agreementType",
  [("store", "fullName")],
  ["store", "storeNumber"],
  ["store", "contractNumber"],
  "createdAt",
];
