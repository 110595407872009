import React from "react";

const Logo = ({ width = "50px", height = "50px", color = "#fff" }) => {
  return (
    <svg
      width={width}
      height={height}
      fill={color}
      id='Layer_1'
      data-name='Layer 1'
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 512 450.91'
    >
      <path d='M462.53,138.71c-45.28-15.52-90.55-31.05-135.84-46.54-22.29-7.63-44.63-15.09-66.86-22.89-4.35-1.53-8.13-1.39-12.39,.1-39.68,13.85-79.41,27.55-119.13,41.29-26.55,9.19-53.1,18.39-79.67,27.54-1.17,.4-2.49,.36-3.75,.52-1.61,.6-1.88-.16-1.53-1.53,62.4-30.43,124.78-60.89,187.22-91.22,7.26-3.52,15.03-6,21.61-10.86,1.02,0,2.03,0,3.05,0,46.63,22.8,93.25,45.6,139.88,68.39,22.98,11.23,45.97,22.45,68.95,33.68,.35,1.37,.08,2.12-1.54,1.54Z' />
      <path d='M433.75,201.01c-9.03,21.08-18.14,42.13-27.08,63.25-11.53,27.26-22.94,54.57-34.34,81.89-1.21,2.91-2.91,4.18-6.23,4.38-14.45,.85-14.12,.84-19.71-12.44-17.48-41.51-35.22-82.91-52.84-124.36-2.23-5.24-4.34-10.53-7.83-15.53v7.16c0,45.18,0,90.36,0,135.53,0,2.03,0,4.06-.07,6.09-.07,2.08-.99,3.38-3.27,3.23-1.86-.13-2.63-1.32-2.71-3.03-.08-1.77-.08-3.55-.08-5.33,0-54.82-.04-109.65,.09-164.47,0-3.01-2.18-7.87,3.77-8.14,4.89-.23,9.86-1.36,12.62,5.26,22.48,53.94,45.24,107.78,67.96,161.62,.65,1.55,.97,3.51,3.65,4.29,6.17-14.55,12.37-29.14,18.55-43.74,14.91-35.18,29.81-70.35,44.7-105.54,2.27-5.36,4.79-10.65,6.53-16.18,2.11-6.71,7.16-6.19,12.24-5.71,5.9,.55,3.58,5.33,3.59,8.32,.14,55.08,.12,110.15,.04,165.23,0,2.52,1.53,6.47-2.18,7.18-4.87,.94-10.24,1.7-14.86-.62-2.35-1.17-1.13-4.69-1.13-7.14-.05-44.42-.04-88.83-.04-133.25v-7.59c-.46-.12-.92-.24-1.38-.36Z' />
      <path d='M240.01,260.59c0,27.39-.22,54.79,.16,82.18,.09,6.76-2.47,7.99-8.42,7.83-5.47-.14-8.77-.3-8.73-7.52,.3-55.55,.3-111.1,0-166.65-.04-7.34,3.45-7.24,8.8-7.44,6.05-.23,8.43,1.23,8.34,7.89-.36,27.9-.15,55.8-.15,83.7Z' />
      <path d='M104.5,389.99c-14.44,0-28.88,.05-43.32-.05-1.97-.01-5.34,1.07-5.03-2.58,.23-2.74,3.19-1.87,5.04-1.88,29.39-.06,58.78-.05,88.17,0,1.89,0,4.76-.61,4.76,2.24,0,2.84-2.87,2.24-4.77,2.25-14.95,.05-29.9,.03-44.84,.03Z' />
      <path d='M403.71,393.05c-14.44,0-28.89,.04-43.33-.04-2.07-.01-5.47,.99-5.5-2.21-.04-3.26,3.38-2.26,5.43-2.26,29.14-.07,58.28-.07,87.42,.01,1.96,0,5.34-1.09,5.08,2.54-.2,2.75-3.15,1.92-5.01,1.93-14.7,.07-29.39,.04-44.09,.04Z' />
      <path d='M462.53,138.71c.51-.51,1.02-1.03,1.54-1.54,3.14,1.8,7.29,1.75,11.11,6.55-5.41-2.18-9.43-2.4-12.65-5.01Z' />
      <path d='M43.36,137.18c.51,.51,1.02,1.02,1.53,1.53-2.11,1.94-4.75,2.43-8.07,1.8,2.18-1.11,4.36-2.22,6.54-3.33Z' />
      <path d='M171.1,267.1c-8.24-4.06-16.96-6.03-26.11-8.16,16.85-6.98,27.39-18.79,30.15-36.59,2.41-15.53-.09-29.61-13.87-39.87-13.96-10.4-30.3-12.34-46.71-13.12-17.23-.82-34.53,.01-51.79-.33-5.66-.11-7.38,1.55-7.36,7.29,.2,55.57,.22,111.15-.03,166.72-.03,6.33,2.18,7.59,7.91,7.53,23.11-.27,46.22-.03,69.33-.17,11.97-.08,23.71-2.23,34.82-6.58,16.44-6.43,24.14-19.74,25.12-36.56,1.01-17.32-4.5-31.81-21.44-40.15Zm-98.87-16.35c.35-11.67,.11-23.35,.11-35.03,0-11.17,.2-22.34-.09-33.5-.13-5.07,1.14-7.43,6.75-7.1,16.68,.98,33.54-1.9,50.07,1.74,17.06,3.76,26.83,16.25,27.04,33.74,.06,5.3,.23,10.71-.65,15.91-3.75,22.14-17.43,29.23-38.89,30.82-12.38,.92-24.89-.03-37.33,.29-5.25,.13-7.17-1.44-7.01-6.87Zm100.36,62.94c-2.8,17.03-17.18,29.03-34.63,30.74-19.24,1.89-38.49,1.04-57.74,1.5-5.95,.14-8.16-1.9-7.98-7.91,.34-11.41,.1-22.84,.1-34.26,0-11.42,.11-22.84-.06-34.26-.06-4.03,.95-6.16,5.5-5.98,21.52,.88,43.09-1.46,64.59,1.52,17.08,2.36,30.16,16.28,30.53,33.45,.11,5.07,.51,10.25-.3,15.2Z' />
      <g>
        <path d='M177.35,403.06h4.74v-29.22h-4.74v-.6h13.75v.6h-4.74v13.97h14.22v-13.97h-4.74v-.6h13.75v.6h-4.74v29.22h4.74v.6h-13.75v-.6h4.74v-14.65h-14.22v14.65h4.74v.6h-13.75v-.6Z' />
        <path d='M213.78,388.41c0-2.28,.45-4.38,1.35-6.32,.9-1.94,2.05-3.62,3.46-5.04,1.41-1.42,3-2.54,4.76-3.35,1.77-.81,3.53-1.22,5.3-1.22,1.88,0,3.71,.41,5.49,1.22,1.78,.81,3.37,1.93,4.76,3.35,1.39,1.42,2.51,3.1,3.35,5.04,.84,1.94,1.26,4.04,1.26,6.32,0,1.99-.38,3.94-1.15,5.85-.77,1.91-1.82,3.6-3.16,5.08-1.34,1.48-2.91,2.68-4.72,3.59-1.81,.91-3.75,1.37-5.83,1.37s-4.12-.44-5.94-1.32c-1.82-.88-3.4-2.06-4.72-3.52s-2.36-3.16-3.1-5.08c-.74-1.92-1.11-3.91-1.11-5.96Zm4.91,0c0,2.56,.17,4.8,.51,6.71,.34,1.91,.91,3.5,1.71,4.78,.8,1.28,1.82,2.24,3.08,2.86,1.25,.63,2.8,.94,4.66,.94s3.37-.31,4.63-.94c1.27-.63,2.3-1.58,3.1-2.86,.8-1.28,1.37-2.88,1.71-4.78s.51-4.14,.51-6.71c0-1.96-.13-3.87-.38-5.7-.26-1.84-.75-3.47-1.47-4.91-.73-1.44-1.74-2.58-3.03-3.44-1.3-.85-2.98-1.28-5.06-1.28s-3.77,.43-5.06,1.28c-1.3,.85-2.31,2-3.03,3.44-.73,1.44-1.22,3.08-1.47,4.91-.26,1.84-.38,3.74-.38,5.7Z' />
        <path d='M247.31,373.25h13.75v.6h-4.74v20.63c0,1.71,.15,3.15,.45,4.34,.3,1.18,.75,2.14,1.35,2.86,.6,.73,1.34,1.25,2.22,1.56,.88,.31,1.89,.47,3.03,.47,1.59,0,3-.23,4.23-.7,1.22-.47,2.28-1.3,3.16-2.5,.28-.37,.53-.74,.75-1.11,.21-.37,.4-.8,.55-1.3,.16-.5,.28-1.1,.36-1.79,.09-.7,.13-1.56,.13-2.58v-19.86h-4.74v-.6h10.12v.6h-4.74v20.72c0,1.2-.13,2.29-.38,3.27s-.78,2-1.58,3.05c-.77,1.03-1.84,1.85-3.2,2.48-1.37,.63-3.18,.94-5.42,.94-3.42,0-6.03-.84-7.84-2.52-1.81-1.68-2.71-4.26-2.71-7.73v-20.2h-4.74v-.6Z' />
        <path d='M282.03,394.48h.47c.31,1.54,.72,2.83,1.22,3.89,.5,1.05,1.05,1.92,1.64,2.61s1.22,1.21,1.88,1.58c.65,.37,1.32,.64,1.99,.81,.67,.17,1.31,.27,1.92,.3,.61,.03,1.19,.04,1.73,.04,1.51,0,2.75-.23,3.72-.68,.97-.46,1.74-1.03,2.31-1.73,.57-.7,.97-1.45,1.2-2.26,.23-.81,.34-1.56,.34-2.24,0-1-.19-1.85-.56-2.56s-.84-1.32-1.41-1.82c-.57-.5-1.19-.9-1.86-1.22s-1.3-.56-1.9-.73l-4.91-1.49c-2.31-.71-4.05-1.65-5.23-2.82-1.18-1.17-1.77-2.73-1.77-4.7,0-1.28,.23-2.47,.68-3.57,.46-1.1,1.1-2.04,1.92-2.84,.83-.8,1.82-1.42,2.97-1.88,1.15-.46,2.41-.68,3.78-.68,1,0,1.83,.09,2.5,.26,.67,.17,1.25,.36,1.73,.58,.48,.21,.92,.41,1.3,.58,.38,.17,.78,.26,1.17,.26,.37,0,.62-.05,.75-.15s.31-.35,.53-.75h.6v7.73h-.51c-.17-1.03-.46-2.01-.88-2.97-.41-.95-.95-1.79-1.6-2.52-.66-.73-1.45-1.31-2.37-1.75-.93-.44-2-.66-3.22-.66-.68,0-1.44,.09-2.26,.28-.83,.19-1.59,.51-2.31,.96-.71,.46-1.3,1.05-1.77,1.79-.47,.74-.7,1.68-.7,2.82,0,.91,.18,1.69,.55,2.33s.85,1.18,1.43,1.62c.58,.44,1.24,.81,1.96,1.11s1.46,.56,2.2,.79l5.17,1.62c.54,.17,1.22,.4,2.05,.68,.83,.29,1.63,.71,2.41,1.28,.78,.57,1.45,1.32,2.01,2.26,.55,.94,.83,2.14,.83,3.59,0,1.34-.25,2.61-.75,3.82-.5,1.21-1.22,2.28-2.16,3.2-.94,.93-2.08,1.67-3.42,2.22-1.34,.55-2.85,.83-4.53,.83-.6,0-1.13,0-1.58-.02-.46-.01-.88-.05-1.28-.11s-.8-.12-1.2-.19c-.4-.07-.84-.18-1.32-.32-.57-.17-1.18-.37-1.84-.6-.65-.23-1.22-.34-1.71-.34-.43,0-.72,.09-.88,.26-.16,.17-.29,.4-.41,.68h-.64v-9.18Z' />
        <path d='M308.6,403.06h4.74v-29.22h-4.74v-.6h24.43v7.39h-.47c-.37-1.11-.77-2.09-1.2-2.93s-.94-1.55-1.54-2.14c-.6-.58-1.31-1.02-2.14-1.3s-1.82-.43-2.99-.43h-7.09v13.2h2.78c.94,0,1.74-.08,2.39-.26,.65-.17,1.22-.49,1.69-.96s.87-1.11,1.2-1.92c.33-.81,.65-1.86,.96-3.14h.47v13.28h-.47c-.2-.83-.41-1.62-.62-2.39-.21-.77-.51-1.45-.9-2.05-.38-.6-.9-1.08-1.54-1.43s-1.47-.53-2.5-.53h-3.46v15.42h8.07c1.57,0,2.87-.33,3.91-1s1.89-1.47,2.54-2.39c.65-.93,1.14-1.89,1.45-2.88s.51-1.82,.6-2.48h.47v9.35h-26.05v-.6Z' />
      </g>
    </svg>
  );
};

export default Logo;
